<template>
  <div>
    <!--滑动区域 ref='mescroll'不能删, 目的是路由切换时可通过ref调用mescroll-vue组件的beforeRouteEnter方法-->
    <mescroll-vue
      ref="mescroll"
      :style="{
        top: down.top ? down.top : '0',
        bottom: down.bottom ? down.bottom : '50px',
      }"
      :up="newUp"
      :down="newDown"
      @init="up.initCallBack"
    >
      <slot name="scrollList" />
    </mescroll-vue>
  </div>
</template>
<script>
import Vue from 'vue'
import { Sticky } from 'vant'

Vue.use(Sticky)
// 引入mescroll的vue组件
import MescrollVue from 'mescroll.js/mescroll.vue'
import MescrollMixins from '@/utils/MescrollMixins.js'

export default {
  name: 'MescrollComponent',
  components: {
    MescrollVue
  },
  mixins: [MescrollMixins],
  props: {
    up: {
      type: Object,
      default: null,
      require: true
    },
    down: {
      type: Object,
      default: null,
      require: true
    }
  },
  data() {
    return {
      isFixed: false,
      offsetTop: 0,
      offsetHeight: 0,
      headerFixed: 0,
      newData: null
    }
  },
  computed: {
    newUp() {
      const newUpObj = this.up
      const empty = {
        // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
        warpId: 'dataList', // 父布局的id;
        icon: './static/mescroll/mescroll-empty.png', // 图标,支持网络图
        tip: '暂无相关数据~' // 提示
      }
      newUpObj.htmlLoading =
        '<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">正在加载..</p>'
      // let toTop = {
      //   src: "./static/mescroll/mescroll-totop.png", // 回到顶部按钮的图片路径,支持网络图
      //   offset: 1500,
      //   // warpClass:'warpClass'
      // };

      if (!newUpObj.htmlNodata) {
        newUpObj.htmlNodata = '<p class="endsClass">---- END ----</p>'
      }
      // if (newUpObj.toTop) {
      //   newUpObj.toTop = toTop;
      // }
      if (!newUpObj.empty) {
        newUpObj.empty = empty
      }
      return newUpObj
    },
    newDown() {
      const newDownObj = this.down
      newDownObj.textLoading = '<p>数据刷新中...</p>'
      newDownObj.htmlContent =
        '<p class="downwarp-progress"></p><p class="downwarp-tip"></p>'
      return newDownObj
    }
  },
  destroyed() {
  },
  mounted() {
    this.setSticky()
    //  window.addEventListener('scroll', this.handleScroll);

    // mescroll.getToBottom()
    // this.setSticky()
  },
  methods: {
    //       setSticky(){
    // let navWarp=document.getElementById("navWarp");

    //     },
    setSticky() {
    }
  }
}
</script>

<style lang="less" scoped>
/*以fixed的方式固定mescroll的高度*/
.mescroll {
  position: fixed;
  /* top: 50px; */
  /* bottom: 50px; */
  /* left: 0;
  right: 0; */
  height: auto;
}

.swiper {
  width: 100%;
  vertical-align: bottom;
}

.nav {
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.nav p {
  display: inline-block;
  width: 30%;
  padding: 10px 0;
}

.nav .active {
  border-bottom: 1px solid #ff6990;
  color: #ff6990;
}

.data-list li {
  position: relative;
  padding: 10px 8px 10px 120px;
  border-bottom: 1px solid #eee;
}

.data-list .pd-img {
  position: absolute;
  left: 18px;
  top: 18px;
  width: 80px;
  height: 80px;
}

.data-list .pd-name {
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  overflow: hidden;
}

.data-list .pd-price {
  margin-top: 8px;
  color: red;
}

.data-list .pd-sold {
  font-size: 12px;
  margin-top: 8px;
  color: gray;
}

/deep/ .warpClass {
  border-radius: 50%;

  // z-index: 9990;
  //   position: fixed;
  //    bottom:100px !important;
  // right: 0;
  //   width: 0.96rem;
  //   height: 0.96rem;
  //   border-radius: 50%;
  //   opacity: 0;
}
</style>
